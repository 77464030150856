<template>
  <div class="auth-wrapper auth-v1">
    <div class="auth-inner">
      <v-card class="auth-card">
        <!-- logo -->
        <v-card-title class="d-flex align-center justify-center py-7">
          <router-link
            to="/"
            class="d-flex align-center"
          >
            <v-img
              :src="require('@/assets/images/logos/logo.png')"
              max-height="80px"
              max-width="80px"
              alt="logo"
              contain
              class="me-3 "
            ></v-img>
          </router-link>
        </v-card-title>
        
        <!-- title -->
        <v-card-text>
          <p class="text-2xl text-center font-weight-semibold text--primary mb-2">
            {{ $t('active.account') }}
          </p>
        </v-card-text>
        <!-- login form -->
        <v-card-text>
          <v-alert
          v-show="alert.active"
          v-model="alert"
          dismissible
          :color="alert.color"
          border="left"
          elevation="2"
          colored-border
          :icon="alert.icons"
        >
          {{alert.text}}
        </v-alert>
          <v-form @submit.prevent="activeCode">
            <v-text-field
              v-model="active.code"
              outlined
              :label="$t('active.code')"
              placeholder="12345"
              hide-details
              class="mb-1"
            ></v-text-field>

            <v-btn
              block        
              :loading="loading"
              :disabled="loading"
              type="submit"
              @click="loader = 'loading'"
              color="primary"
              small
              class="mt-2"
            >
              
              {{ $t('active.send') }}
            </v-btn>
          </v-form>
        </v-card-text> 
    </v-card>
    </div>

    <!-- background triangle shape  -->
    <img
      class="auth-mask-bg"
      height="173"
      :src="require(`@/assets/images/misc/mask-${$vuetify.theme.dark ? 'dark':'light'}.png`)"
    >

    <!-- tree -->
    <v-img
      class="auth-tree"
      width="247"
      height="185"
      src="@/assets/images/pages/kisspng-courier.png"
    ></v-img>

    <!-- tree  -->
    <v-img
      class="auth-tree-3"
      width="377"
      height="289"
      src="@/assets/images/pages/kisspng.png"
    ></v-img>
  </div>
</template>

  <script>
  // eslint-disable-next-line object-curly-newline
  import {  mdiAlertRemove, mdiThumbUp } from '@mdi/js'

  export default {
      data: () => ({
        active: {
          code: undefined
        },
        alert:{
          color: '',
          text:'',
          icons:'',
          active: false
        },
        loader: null,
        loading: false,
        icons: {
          mdiAlertRemove,
          mdiThumbUp
        },
    }),
    methods: {
      activeCode: function () {
        const l = this.loader
        this[l] = !this[l]
        let bodyFormData = new FormData();
        bodyFormData.append("user_form[activationcode]", this.active.code);
        const promise = this.$store.dispatch('activeCode', bodyFormData)
        promise.then((res) => {
          console.log(res)
           this.alert = {
            color: "success",
            active: true,
            text: "Activation reussie!",
            icons: this.icons.mdiThumbUp
          }
          this[l] = false
          this.loader = null

          this.$router.push('/auth/login')
        })
        .catch(err => {
          this.alert = {
            color: "error",
            active: true,
            text: err,
            icons: this.icons.mdiAlertRemove
          }
          this[l] = false
          this.loader = null
        })
        
       this.loading = false
      }
    }
  }
  </script>

  <style lang="scss">
  @import '@/plugins/vuetify/default-preset/preset/pages/auth.scss';
  </style>

  
